import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { MdEmail } from 'react-icons/md';
import { Row, Container, Col, Button } from 'react-bootstrap';

import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
      <Row className='dark-bg' style={{height: '80vh'}}>
        <Col xs={12} className='text-center d-flex align-items-center flex-column justify-content-center'>
          <h1>404</h1>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... maybe you're looking for <a href='/'>this?</a></p>
          <div>
          <a className='unstyled-a' href='https://linkedin.com/in/fullofcaffeine'><FaLinkedin /></a>
          <a className='unstyled-a' href='https://github.com/fullofcaffeine'><FaGithub /></a>
          <a className='unstyled-a' href='https://twitter.com/fullofcaffeine'><FaTwitter /></a>
          </div>
        </Col>
      </Row>
 </Layout>
)

export default NotFoundPage
